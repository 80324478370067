.page-wrapper-height {
  min-height: 250px;
}
.logo-width {
  width: 100%;
}
.header-color {
  background-color: #ded6f4 !important;
  color: black;
}
.topBarLogo {
  width: 20%;
}
.progress-circle {
  display: inline-block;
  position: relative;
  width: 100px;
  height: 100px;
}
.progress-circle-background {
  stroke: #e0e0e0;
}
.progress-circle-fill {
  stroke: #654da4;
  transition: stroke-dashoffset 0.3s;
}
.progress-circle-fill-green {
  stroke: green;
  transition: stroke-dashoffset 0.3s;
}
.progress-circle-fill-orange {
  stroke: orange;
  transition: stroke-dashoffset 0.3s;
}
.progress-circle-fill-lightblue {
  stroke: lightblue;
  transition: stroke-dashoffset 0.3s;
}
.progress-circle-text {
  fill: #000;
  font-size: 18px;
  font-weight: bold;
}

.button {
  background-color: #8590d9 !important;
  color: white !important;
}
.custom-button {
  display: inline-block !important;

  font-size: 10px !important;
  text-align: center !important;
  text-decoration: none !important;
  cursor: pointer !important;
  border: none !important;
  border-radius: 10px !important; /* Adjust the value to control the amount of curve */
  background-color: #8590d9 !important;
  color: white !important;
  transition: background-color 0.3s ease !important;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1) !important;
  margin-right: 5px;
}

.custom-button:hover {
  background-color: #0056b3; /* Change the color on hover */
}
.alert {
  position: relative;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent !important;
  border-radius: 4px;
}

.success {
  background-color: #dff0d8 !important;
  border-color: #d6e9c6 !important;
  color: #3c763d !important;
}

.error {
  background-color: #f2dede !important;
  border-color: #ebccd1 !important;
  color: #a94442 !important;
}

.Login-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

/* Your CSS file or inline styles */
.table-responsive {
  margin: 0px !important;
}

.table {
  width: 100% !important;
  margin-bottom: 1rem !important;
  background-color: #fff !important;
  border: 1px solid #dee2e6 !important;
  color: #212529 !important;
}

.table th {
  padding: 0.5rem !important;
}
.table td {
  padding: 0.5rem !important;
  vertical-align: top !important;
  border-top: 1px solid #dee2e6 !important;
}

.table thead th {
  vertical-align: bottom !important;
  border-bottom: 2px solid #dee2e6 !important;
  font-weight: bold !important;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6 !important;
}

.container-fluid {
  padding: 10px !important;
}

h3 {
  color: #007bff !important;
  margin-bottom: 10px !important;
  margin-left: 0px !important;
}

.table-container {
  background-color: white !important;
  border: 1px solid #dee2e6 !important;
  border-radius: 5px !important;
  overflow: hidden !important;
}

.welcome-section {
  color: black !important;
  margin-bottom: 10px !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  align-items: center !important;
  width: 100% !important;
  padding: 10px !important;
  border: 1px solid #007bff !important;
  border-radius: 5px;
  background-color: #eceef3 !important;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #fff !important;
  }

  > div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 5px 0;

    .bi {
      font-size: 1.5rem;
    }
  }

  > div:last-child {
    display: none;
    padding-top: 10px;
    transition: display 0.3s ease;

    &.visible {
      display: block;
    }

    p {
      margin-bottom: 10px;
    }
  }
}

.custom-input {
  border: none;
  border-bottom: 1px solid #ccc;
  border-radius: 0;
  box-shadow: none;
  padding: 0;
}
.input-group-addon {
  align-items: center;
  bottom: 0;
  display: flex;
  padding: 0 10px;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
}

/* LandingPage.css */

/* Global Styles */
body {
  margin: 0;
  padding: 0;
}

/* Navbar Styles */
.navbar {
  background-color: #fff; /* Set your desired background color */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
  padding: 10px; /* Adjust padding as needed */
}

.topBarLogo {
  max-height: 50px; /* Set the maximum height for your logo */
}

/* Landing Container Styles */
.wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Full viewport height */
}

.landingcontainerflex {
  display: flex;
  padding: 20px; /* Adjust padding as needed */
}

.landingflexitem1 {
  flex: 3; /* 75% width */
  padding: 20px; /* Adjust padding as needed */
}

.landing-h1 {
  font-size: 28px;
  font-weight: bold;
  color: #333; /* Set your desired text color */
}

.landing-h2 {
  font-size: 24px;
  color: #666; /* Set your desired text color */
  margin-bottom: 20px;
}

.landing-h3 {
  font-size: 18px;
  color: #777; /* Set your desired text color */
  line-height: 1.5;
}

.landing-h4 {
  margin-top: 20px;
}

.landing-button {
  margin-right: 10px;
}

.landingflexitem2 {
  flex: 1; /* 25% width */
  padding: 20px; /* Adjust padding as needed */
}

.banner {
  width: 100%; /* Make the image responsive */
  max-height: 400px; /* Set the maximum height for the banner */
}

/* Responsive Styles */
@media (max-width: 768px) {
  .landingcontainerflex {
    flex-direction: column;
  }

  .landingflexitem1,
  .landingflexitem2 {
    width: 100%;
    padding: 20px; /* Adjust padding as needed */
  }
}

.left-menu-text {
  fill: #000 !important;
  font-size: 14px !important;
  font-weight: bold !important;
}

.btn-menu {
  color: #000 !important;
  background-color: #8590d9 !important;
  border-color: #ded6f4 !important;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08) !important;
}

.autocomplete-container {
  position: relative;
}

.suggestions-list {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.suggestion-item {
  padding: 8px;
  cursor: pointer;
}

.suggestion-item:hover {
  background-color: #f2f2f2;
}

.aLink {
  color: #0056b3 !important; /* Default link color */
  text-decoration: none !important;
  cursor: pointer;
}

.aLink:hover {
  color: #0056b3 !important; /* Hover color */
}

.dashboard-alert {
  padding: 20px;
  background-color: #8590d9;
  color: white;
  margin-bottom: 15px;
}

.dashboard-closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

.dashboard-closebtn:hover {
  color: black;
}

.footer-New {
  height: 30px;
  background-color: white;
}

.option-container {
  display: flex;
  /* justify-content: space-between; */
}

.option-box {
  width: 80px;
  height: 40px;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

.option-box:hover {
  background-color: #f0f0f0;
}

.selected {
  background-color: #4caf50;
  color: white;
}
.custom-header-class {
  background-color: red !important;
  color: #333 !important;
  font-weight: bold !important;
}

/* CustomDropdown.css */
.custom-dropdown {
  position: relative !important;
  display: inline-block !important;
}

.dropdown-header {
  cursor: pointer;
  padding: 8px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  background-color: #f8f9fa;
  z-index: 2 !important;
}

.dropdown-options {
  position: absolute !important;
  top: 100%;
  left: 0;
  z-index: 3 !important;
  border: 1px solid #ced4da;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  overflow-y: auto !important;
}

.dropdown-option {
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease !important;
}

.dropdown-option:hover {
  background-color: #007bff;
  color: #fff;
}
.MuiDataGrid-rows {
  overflow: visible !important;
}
.header-row {
  font-weight: bold;
  background-color: #f0f0f0; /* Add your desired background color */
  /* Add other styling properties as needed */
}

.tag-box{
  height: auto !important;
  min-height: auto !important;
  /*display: flex;
   flex-wrap: wrap; */
   
margin-bottom: 10px;
  padding: 2px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  background-color: #f8f9fa;
  z-index: 2 !important;
}

.custom-day-picker .DayPicker-Day--selected {
  background-color: your-desired-color;
  color: your-desired-text-color;
}

.rdp-day_selected, .rdp-day_selected:focus-visible, .rdp-day_selected:hover {
  color: white;
  opacity: 1;
  background-color: #8590d9 !important;
}

/* Header CSS */
.slide-menu {
  position: absolute;
  top: 50px; /* Adjust the top position based on your layout */
  right: 0;
  background-color: white; /* Background color */
  color: black; /* Text color */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #007bff;
  border-radius: 4px;
  padding: 10px;
  z-index: 1000;
  min-width: 150px; /* Set a minimum width for better appearance */
}

.slide-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.slide-menu li {
  margin-bottom: 10px;
}

.slide-menu a {
  text-decoration: none;
  color: black;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.slide-menu a:hover {
  color: blue;
  text-decoration: underline;
}

/* Bootstrap Icons */
.slide-menu .bi {
  margin-right: 10px;
}

/* Profile Picture Styles */
.profile-pic {
  cursor: pointer;
}

.profile-pic img {
  border-radius: 50%;
}

.bg-blue-200{
  background-color: #F9F6FE !important;
}

.bg-yellow-200{
  /* background-color: lightcyan !important; */
} 

 
 /* Scrollbar for WebKit browsers (Chrome, Safari) */
.scrollable-list::-webkit-scrollbar {
  width: 12px;
}

.scrollable-list::-webkit-scrollbar-thumb {
  background-color: #DCD4F2;
   /* rgba(0, 0, 0, 0.5); */
  border-radius: 10px;
}

/* Scrollbar for non-WebKit browsers (Firefox, Edge) */
.scrollable-list {
  scrollbar-width: thin;
  max-height: 300px; /* Set your desired max height */
  overflow-y: auto; /* Enable vertical scrollbar */
}

.scrollable-list::-webkit-scrollbar-track {
  background-color: transparent;
}

.scrollable-list::-webkit-scrollbar-thumb:hover {
  background-color: #CBC0EB;
}


.scrollable-list-big {
  scrollbar-width: thin;
  max-height: 450px; /* Set your desired max height */
  overflow-y: auto; /* Enable vertical scrollbar */
}
.scrollable-list-big::-webkit-scrollbar-track {
  background-color: transparent;
}

.scrollable-list-big::-webkit-scrollbar-thumb:hover {
  background-color: #CBC0EB;
}
.scrollable-list-big::-webkit-scrollbar {
  width: 12px;
}

.scrollable-list-big::-webkit-scrollbar-thumb {
  background-color: #DCD4F2 ;
   /* rgba(0, 0, 0, 0.5); */
  border-radius: 10px;
}


/* Add these styles to your CSS stylesheet */
.top-right-item {
  position: absolute;
  top: 0;
  right: 0;
  /* background-color: green;  
  color: white;   */
  padding: 5px;  
}

.bottom-right-item {
  position: absolute;
  bottom: 0;
  right: 0;
  /* background-color: blue;  
  color: white;   */
  padding: 5px; 
  margin-bottom: -15px;
}

.tooltip {
  font-size: 14px;
 
}

.tooltip-inner {
  background-color: #8590d9 !important; /* Blue background */
  color: #fff; /* White text */
  border-radius: 4px; /* Rounded corners */
  padding: 8px 12px; /* Padding */
  max-width: 600px !important;
  font-size: 14px;
}

.tooltip-arrow {
  color: #8590d9 !important; /* Blue arrow color */
  border-bottom-color: #8590d9 !important; /* Same color as background */
}

.tooltip-content {
  position: relative;
}

.tooltip-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  cursor: pointer;
  font-size: medium;
}

/* .tooltip-close:hover {
  color: #707cd2;
} */

.custom-tooltip {
  background-color: #8590d9 !important; /* Blue background */
  color: #fff !important; /* White text */
  border-radius: 4px !important; /* Rounded corners */
  padding: 8px 12px !important; /* Padding */
  white-space: normal !important; /* Allow text wrapping */
}

.tooltip-close {
  cursor: pointer;
  font-size: 16px;
  position: absolute;
  top: 4px;
  right: 4px;
}

.help-icon {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
}
